<template>
  <ion-page>
    <ion-content>
      <div style="text-align:center; margin: 15px;" data-header-ad-wrapper>
        <VueScriptComponent v-if="adLinkTop && adLinkTop !=''" :script="adLinkTop" />
      </div>
      <ion-grid>
          <ion-row>
            <ion-col>
                  <div class="refresh">
                    Aktualisierung alle <select name="refresh" class="refresh" v-model="selectedRefresh">
                      <option v-for="refresh in refreshSelect" :key="refresh.id" :value="refresh.id">{{ refresh.name }}</option>
                      </select>
                      <a @click="refreshScore()" class="refresh"><img src="~@/assets/images/refresh.gif"></a>
              </div>
            </ion-col>
            <ion-row  v-if="currentTicker && currentTicker.tickerText != ''">
            <ion-col>
              <marquee-text class="ticker-text" :key="currentTicker.id" :duration="8" :repeat="15">
                {{ currentTicker.tickerText }}&nbsp; &nbsp; &nbsp; 
              </marquee-text>
            </ion-col>
          </ion-row>
          </ion-row>
      </ion-grid>
      <table>
        <tr>
          <td>
            <div class="roundInfo">
              <ion-select interface="action-sheet" v-model="selectedFlight" @ionChange="changeFlight($event.target.value)">
                <ion-select-option value="0">Flight A</ion-select-option>
                <ion-select-option value="1">Flight B</ion-select-option>
              </ion-select>
            </div>
          </td>
          <td>
            <div class="roundInfo">
              <ion-select interface="action-sheet" v-model="selectedRound" @ionChange="changeRound($event.target.value)">
                <ion-select-option value="0">Runde 1</ion-select-option>
                <ion-select-option value="1">Runde 2</ion-select-option>
                <ion-select-option value="2">Runde 3</ion-select-option>
              </ion-select>
              </div>
          </td>
        </tr>
      </table>

      <ion-grid v-if="bracketsFound && bracketsFound.length == 0">
        <ion-row>
          <ion-col>
            <div class="head">
              <p>Keine Matches gefunden</p>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>

      <table class="ranking matchplay" v-for="(result, i) in bracketsFound" :key="i" :value="result">
          <colgroup>
          <col width="40%">
          <col width="60">
          <col width="100">
          <col width="60">
          <col width="40%">
          </colgroup>
          <tbody>
          <tr>
          <td colspan="5">
          <div class="roundInfo"> {{ result.header }}</div>
          </td>
          </tr>
          <tr>

          <th style="text-align:right">Team</th>
          <th style="text-align: center">Punkte</th>
          <th style="text-align: center">Ergebnis</th>
          <th style="text-align: center">Punkte</th>
          <th style="text-align:left">Team</th>
          </tr>

          <tr v-for="(match, j) in result.match" :key="j" :value="match" >
            <td style="text-align:right">
               {{ match.team1 }}
              </td>
              <td style="text-align: center;">{{ match.team1Points }}</td>
              <td style="text-align: center;">{{ match.team1Points }} : {{ match.team2Points }}</td>
              <td style="text-align: center;">{{ match.team2Points }}</td>
              <td>{{ match.team2 }}</td>
          </tr>
          <template  v-for="(match, j) in result.matches" :key="j" :value="match">
            <tr class="ranking matchplay" >
              <td style="text-align:right">
                <a v-if="match.result" class="openScore" @click="match.showScorecard == true ? match.showScorecard = false : match.showScorecard = true">{{ match.players1 }}</a>
                {{ match.result == undefined ? match.players1 : '' }}
              </td>
              <td style="text-align: center;">
                <a v-if="match.result" class="openScore" @click="match.showScorecard == true ? match.showScorecard = false : match.showScorecard = true">{{ match.points1 }}</a>
                {{ match.result == undefined ? match.result : '' }}
              </td>
              <td style="text-align: center;">
                <a v-if="match.result" class="openScore" @click="match.showScorecard == true ? match.showScorecard = false : match.showScorecard = true">{{ match.result }}</a>
                {{ match.result == undefined ? match.result : '' }}
              </td>
              <td style="text-align: center;">
                <a v-if="match.result" class="openScore" @click="match.showScorecard == true ? match.showScorecard = false : match.showScorecard = true">{{ match.points2 }}</a>
                {{ match.result == undefined ? match.result : '' }}
              </td>
              <td>
                <a v-if="match.result" class="openScore" @click="match.showScorecard == true ? match.showScorecard = false : match.showScorecard = true">{{ match.players2 }}</a>
                {{ match.result == undefined ? match.players2 : '' }}
              </td>
            </tr>
            <tr class="playerScore" v-if="match.result != undefined && match.showScorecard == true">
              <td colspan="5">
              <table class="playerScore matchplay">
                <tbody>
                <tr>
                  <td>Loch</td>
                  <td v-for="(hole, h) in match.numHoles" :key="h" :value="hole">{{ h+1 }}</td>
                </tr>
                <tr>
                  <td>{{match.players1}}</td>
                  <td v-for="(hole, h) in match.numHoles" :key="h" :value="hole">{{ (match.player1Scores[h] -  match.player2Scores[h]) > 0 ? (match.player1Scores[h] - match.player2Scores[h]) + ' auf' : '' }}</td>
                </tr>
                <tr class="as">
                  <td></td>
                  <td v-for="(hole, h) in match.numHoles" :key="h" :value="hole">{{ (match.player1Scores[h] != null && match.player1Scores[h] != -2 && match.player1Scores[h] == match.player2Scores[h]) ? 'AS' : '' }}</td>
                </tr>
                <tr>
                  <td>{{match.players2}}</td>
                  <td v-for="(hole, h) in match.numHoles" :key="h" :value="hole">{{ (match.player2Scores[h] - match.player1Scores[h]) > 0 ? (match.player2Scores[h]  - match.player1Scores[h]) + ' auf' : '' }}</td>
                </tr>
                </tbody>
              </table>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
        <ion-grid>
          <ion-row>
            <ion-col>
              <div style="text-align:center; max-width: 1024; width: 100%; height: auto; margin:0 auto;">
                <div><img v-if="footerImg && footerImg != ''" width="1024" :src="footerImg"></div>
              </div>                
              <div style="font-size:small; color: black; margin:0 auto;">
                <div><a href="https://www.clubinone.de" target="_blank">Powered by CLUB IN ONE</a></div>
              </div>                
            </ion-col>
          </ion-row>
        </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import { toastController, modalController, loadingController, IonTitle, IonButton, IonToolbar, IonHeader, 
IonMenuButton, IonButtons, IonGrid, IonImg, IonCol, IonRow, IonItemDivider, IonSearchbar, IonCheckbox,
IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, 
IonNote, IonRouterOutlet, IonSplitPane, IonSelect, IonSelectOption, IonAvatar, IonFabList, 
IonFabButton, IonFab, IonCardContent, IonCardHeader, IonCardTitle, IonCard, IonPage, isPlatform } from '@ionic/vue';
import { defineComponent, ref, watch } from 'vue';
import { useQuery, useResult } from '@vue/apollo-composable'
import findBracketRoundQueryFile from '../graphql/findBracketRound.query.gql'
import { useRoute, useRouter } from 'vue-router';
import { chevronBackOutline, chevronForwardOutline, arrowDownSharp, arrowUpSharp } from 'ionicons/icons';
import * as dayjs from 'dayjs';
import VueScrollingTable from "vue-scrolling-table"
import "/node_modules/vue-scrolling-table/dist/style.css"
import VueScriptComponent from 'vue-script-component'
import MarqueeText from 'vue-marquee-text-component'

export default defineComponent({
  name: 'Home',
  components: {
    IonApp, IonImg, IonAvatar, IonPage,
    IonContent, 
    IonIcon, 
    IonItem, 
    IonLabel, VueScrollingTable,
    IonList, 
    IonListHeader, 
    IonMenu, 
    IonMenuToggle, 
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane,
    IonSearchbar,
    IonItemDivider,
    IonGrid, 
    IonCol, 
    IonRow,
    IonTitle, IonButton, IonToolbar, IonHeader, IonMenuButton, IonButtons, IonSelect, IonSelectOption,
    IonFabList, IonFabButton, IonFab, IonCardContent, IonCardHeader, IonCardTitle, IonCard, IonCheckbox, VueScriptComponent, MarqueeText
  },
  data() {
    return {
      logo: require('@/assets/images/logo/nrwlogo.png'),
      weekDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
    }
  },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const selectedFlight = ref(route.params.groupid)
    const selectedRound = ref(route.params.roundid)
    let selectedRefresh = ref(1)
    const refreshSelect = [{ id: 0, name: 'Nein' }, { id: 1, name: '5 Minuten', selected: true }]

    const {onResult, result: findBracketRoundResult, refetch: findBracketRoundRefetch } = useQuery(findBracketRoundQueryFile, () => ({ group: parseInt(route.params.groupid), round_id: parseInt(route.params.roundid), league_id: parseInt(route.params.lid) }), { fetchPolicy: 'no-cache', pollInterval: selectedRefresh.value == 0 ? 0 : 60000*5 } )
    const bracketsFound = useResult(findBracketRoundResult, null, data => data.findBracketRound.bracket)
    const adLinkTop = useResult(findBracketRoundResult, null, data => data.findBracketRound.adLinkTop)
    const footerImg = useResult(findBracketRoundResult, null, data => data.findBracketRound.footerImg)
    const currentTicker = useResult(findBracketRoundResult, null, data => data.findBracketRound.currentTicker)

    return {
      bracketsFound, chevronBackOutline, chevronForwardOutline, dayjs, arrowDownSharp, arrowUpSharp, isPlatform, selectedFlight, selectedRound, adLinkTop, footerImg, refreshSelect, selectedRefresh, findBracketRoundRefetch, currentTicker
    }
  },
  methods: {
    refreshScore() {
      this.findBracketRoundRefetch()
    },
    showScorecard() {
      console.log('here')
    },
    changeFlight(flight) {
      this.$router.push('/' + this.$route.params.lid + '/' + flight  + '/' + this.$route.params.roundid)
    },
    changeRound(round) {
      this.$router.push('/' + this.$route.params.lid + '/' + this.$route.params.groupid  + '/' + round)
    },
  }
});

if (document.location.search.match(/type=embed/gi)) {
  window.parent.postMessage("resize", "*");
}

</script>

<style scoped>
.cf:after {
    content: "";
    display: table;
    clear: both;
}

div.wrap,
div.error {
    padding: 7px;
}

div.wrap.noTop {
    padding-top: 0;
}

div.wrap ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

div.wrap > ul > li {
    padding-right: 4px;
    padding-bottom: 15px;
    width: 25%;
    float: left;
}

div.wrap ul li h2 {
    font: bold 1.5em/1.5 "Open Sans", Arial, Verdana, sans-serif;
    padding-bottom: 3px;
    border-bottom: 1px solid;
    margin: 0;
}

div.wrap > ul > li > ul {
    padding-right: 30px;
    padding-top: 7px;
}

div.wrap > ul > li > ul > li {
    padding: 10px 5px 10px 1em;
    font-size: 1.2em;
    font-weight: 600;
    cursor: pointer;
}

div.wrap > ul > li > ul > li > ul {
    padding-left: 20px;
}

div.wrap > ul > li > ul > li > ul {
    margin-top: 5px;
    padding-top: 5px;
    border-top: solid 2px;
    display: none;
}

div.wrap > ul > li > ul > li > ul > li {
    padding: 5px 0;
}

div.wrap ul a {
    text-decoration: none;
    font-weight: normal;
}

div.wrap ul a:hover {
    text-decoration: underline;
}

div.refresh {
    float: right;
    line-height: 1;
    padding-top: 7px;
}

div.refresh select {
    font-size: 12px;
    margin: 0;
    width: 100px;
}

div.refresh a {
    display: inline-block;
    cursor: pointer;
}

div.filter {
    float: left;
    max-width: 400px;
    padding-left: 7px;
}

div.filter a {
    text-decoration: none;
    padding-right: 20px;
}

div.filter select {
    display: block;
    width: 100%;
}

ion-content {
    margin: 0;
    padding: 0;
    font: normal 12px/1.3 Arial, Verdana, sans-serif !important;
}

select {
    margin-top: 7px;
    font-size: 1.2em;
    font-weight: 600;
    height: 30px;
    max-width: 100%;
    padding: 4px 6px;
    border: 1px solid;
}

span.square {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: solid 1px;
    margin-left: 20px;
    margin-bottom: -4px;
}

p.legend {
    font-size: 12px;
    text-align: right;
    padding-right: 20px;
    margin-bottom: 15px;
}

p.right {
    text-align: right;
}

img {
    max-width: 100%;
    height: auto;
}

div.controls {
    margin: 0 0 20px 0;
}

div.head {
    font-size: 19px;
    font-weight: bold;
    border-bottom: 1px solid;
}

div.head span {
    display: inline-block;
}

div.head span.rank {
    width: 50px;
    padding-left: 5px;
}

div.head span.score {
    width: 70px;
    float: right;
    text-align: center;
}

.ticker-text {
    height: 150%;
    font-size: 25px;
    color: red;
}

div.roundInfo {
    padding: 16px 0 5px 0;
    font-size: 18px;
}

table.ranking span,
table.singleRanking span {
    text-decoration: none;
    cursor: pointer;
}

table.ranking tr.sum > td {
    border-top: solid 2px;
}

table.ranking,
table.singleRanking {
    width: 100%;
    table-layout: auto;
    margin-bottom: 30px;
}

table.ranking tr,
table.singleRanking tr {
    height: 30px;
}

table.ranking > thead > tr > th,
table.singleRanking > thead > tr > th {
    padding: 3px 5px;
    font-weight: bold;
    font-size: 13px;
    text-align: center;
    border: solid 1px;
    border-top: none;
    border-bottom-width: 3px;
    width: 75px;
}

table.ranking > thead > tr > th:nth-child(1),
table.ranking > thead > tr > th:nth-child(2),
table.singleRanking > thead > tr > th:nth-child(2),
table.singleRanking > thead > tr > th:nth-child(3),
table.singleRanking > tbody > tr > td:nth-child(2),
table.singleRanking > tbody > tr > td:nth-child(3) {
    width: auto;
    text-align: left !important;
}

table.ranking > thead > tr > th:first-child,
table.ranking > tbody > tr > td:first-child,
table.singleRanking > thead > tr > th:first-child,
table.singleRanking > tbody > tr > td:first-child {
    border-left: none;
}

table.ranking > thead > tr > th:last-child,
table.ranking > tbody > tr > td:last-child,
table.singleRanking > thead > tr > th:last-child,
table.singleRanking > tbody > tr > td:last-child {
    border-right: none;
}

table.ranking > tbody > tr > td,
table.singleRanking > tbody > tr > td {
    font-size: 14px;
    padding: 3px 5px;
    border: solid 1px;
    text-align: center;
}

table.ranking.matchplay > tbody > tr > td {
    text-align: left;
}

table.ranking > tbody > tr > td:nth-child(1),
table.ranking > tbody > tr > td:nth-child(2) {
    text-align: left;
}

table.ranking tr.sum > td {
    border-top: solid 2px;
}

table.ranking > tbody > tr.teamEnd > td,
table.singleRanking > tbody > tr.teamEnd > td {
    border-bottom: solid 3px;
}

table.ranking tr.playerScore,
table.singleRanking tr.playerScore {
    display: table-row;
}

table.ranking tr.playerScore > td,
table.singleRanking tr.playerScore > td {
    padding: 5px 3px;
}

table.ranking a,
table.singleRanking a {
    text-decoration: none;
}

table.ranking a:hover,
table.singleRanking a:hover {
    text-decoration: underline;
}

table.ranking > tbody > tr > td {
    font-size: 14px;
    padding: 3px 5px;
    border: solid 1px;
    text-align: center;
}

table.playerScore {
    width: 100%;
}

table.playerScore thead tr th {
    font-weight: normal;
    border: solid 1px;
    border-top: none;
    border-bottom-width: 3px;
    text-align: center;
    padding: 3px 5px;
}

table.playerScore > tbody > tr > td {
    border: solid 1px;
    text-align: center;
    padding: 3px 5px;
}

table.playerScore > thead > tr > th:first-child,
table.playerScore > tbody > tr > td:first-child {
    border-left: none;
}

table.playerScore > thead > tr > th:last-child,
table.playerScore > tbody > tr > td:last-child {
    border-right: none;
}

table.playerScore td.small,
table.playerScore th.small {
    width: 32px;
}

table.playerScore tr :nth-child(2) {
    width: 40px;
}

table.playerScore tr :nth-child(21) {
    width: 58px;
}

table.playerScore tbody tr td {
}

table.playerScore tbody tr td:first-child {
    font-weight: normal !important;
    text-align: right;
}

table.playerScore tbody tr:first-child td {
    font-weight: bold;
}

table.playerScore.matchplay tr td {
    width: 50px;
}

table.playerScore.matchplay tr td:first-child {
    width: auto;
}

table.playerScore.matchplay tr.as td {
}

.eagle {
    background: #ff9050 !important;
}

.birdie {
    background: #ff7070 !important;
}

.par {
    background: lightgray !important;
}

.bogey {
    background: #80bb80 !important;
}

.doublebogey {
    background: #a0a0cc !important;
}

.triplebogey {
    background: #60608c !important;
}

span.square {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: solid 1px;
    margin-left: 20px;
    margin-bottom: -4px;
}

p.legend {
    font-size: 12px;
    text-align: right;
    padding-right: 20px;
    margin-bottom: 15px;
}

p.right {
    text-align: right;
}

img {
    max-width: 100%;
    height: auto;
}

</style>