import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:lid',
    name: 'Home1',
    component: Home
  },
  {
    path: '/:lid/:groupid',
    name: 'Home2',
    component: Home
  },
  {
    path: '/:lid/:groupid/:roundid',
    name: 'Home2',
    component: Home
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
